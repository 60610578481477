<template>
 <div class="bigDiv">
<div class="topImg"><img src="https://oss.hshwhkj.com/images/ces.png" alt=""></div>
<div class="gongsi" v-if="tg_id==1">该内容由上海河山汇文化科技有限公司提供</div>
<div class="gongsi" v-if="tg_id==2">该内容由苏州安捷成文化科技有限公司提供</div>
<div class="gongsi" v-if="tg_id==3">该内容由苏州尚易真网络科技有限公司提供</div>
<div class="bodyCard">
<div class="text animate__backInUp" >
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo" v-if="textflagA">
您好！欢迎加入易学公开课，我是您的专属客服小丽
了解您的基本情况，便于分配专业老师为您服务，请认真回答!</div>
</div>
<div class="text animate__backInUp"  v-if="textflagB">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo" style="color: red;">
回答完毕后，添加老师微信免费领取《5天易学公开课》》名额有限~</div>
</div>
<div class="text animate__backInUp"  v-if="textflagC">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo"  >
您的性别是?</div>
</div>


<!-- 回答 -->
<div class="userTExt"  v-if="textflagD">
<img src="https://oss.hshwhkj.com/images/lqkc.png" alt="">
<div class="textinfo"  >
{{genter}}</div>
</div>

<div class="text"  v-if="textflagE">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo"  >
您的年龄是?</div>
</div>

<!-- 回答 -->
<div class="userTExt"  v-if="textflagF">
<img src="https://oss.hshwhkj.com/images/lqkc.png" alt="">
<div class="textinfo"  >
{{old}}</div>
</div>

<div class="text"  v-if="textflagG">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo"  >
您希望学习《易经》中的哪些知识?</div>
</div>
<!-- 回答 -->
<div class="userTExt"  v-if="textflagH">
<img src="https://oss.hshwhkj.com/images/lqkc.png" alt="">
<div class="textinfo"  >
{{info}}</div>
</div>

<div class="text"  v-if="textflagI">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo"  >
您是否每天愿意花1小时学习易经?</div>
</div>
<!-- 回答 -->

<div class="userTExt"  v-if="textflagJ">
<img src="https://oss.hshwhkj.com/images/lqkc.png" alt="">
<div class="textinfo"  >
{{yiyuan}}</div>
</div>
<div class="text"  v-if="textflagK">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo"  >
您是否愿意免费领取5天易学直播课?</div>
</div>

<div class="userTExt"  v-if="textflagL">
<img src="https://oss.hshwhkj.com/images/lqkc.png" alt="">
<div class="textinfo"  >
{{lingqu}}</div>
</div>
<div class="text"  v-if="textflagM">
<img src="https://oss.hshwhkj.com/images/tx.jpeg" alt="">
<div class="textinfo" style="color: red;" >
 恭喜您!0元获得易学直播课学习资格,名额仅剩10个,学习易经,掌握自己的福报,点击下方添加老师领取课程!
</div>
</div>
</div>

<!-- 问题选项 -->

<!-- 1 -->
<div class="wenti" v-if="wendaA">
  <div @click="selectGenter(1)">男</div>
  <div @click="selectGenter(0)">女</div>
</div>

<!-- 2 -->
<div class="wenti" v-if="wendaB">
  <div @click="selectOld('30-40岁')">30-40岁</div>
  <div @click="selectOld('40-50岁')">40-50岁</div>
  <div @click="selectOld('50-60岁')">50-60岁</div>
  <div @click="selectOld('60岁以上')">60岁以上</div>
</div>
<!-- 3 -->
<div class="wenti" v-if="wendaC">
  <div @click="selectinfo('面相识人学')">面相识人学</div>
  <div @click="selectinfo('旺运姓名学')">旺运姓名学</div>
  <div @click="selectinfo('家居风水学')">家居风水学</div>
  <div @click="selectinfo('八字命理学')">八字命理学</div>
</div>
<!-- 4 -->
<div class="wenti" v-if="wendaD">
  <div @click="selectyiyuan('1')" style="backgroundColor: #cb1010">不愿意</div>
  <div @click="selectyiyuan('2')" style="backgroundColor: #cb1010">愿意</div>
</div>
<!-- 5 -->
<div class="wenti" v-if="wendaE">
  <div @click="selectLingqu('不要了')" style="backgroundColor: #cb1010">不要了</div>
  <div @click="selectLingqu('马上领取')" style="backgroundColor: #cb1010">马上领取</div>
</div>
<!-- 底部领取盒子 -->
<!-- v-if="wendaE" -->
<div class="btn_fixed"  v-if="wendaF" @click="onsubmit" >
  <el-button type="primary" class="btn_fixed_btn">立即领取</el-button>
</div>
 </div>
</template>

<script>
import {getWxCsad} from "../../api/360AD.js";

 export default {
 
  data () {
   return {
      tg_id:'',

       ruleForm: {
                land_link: "", //落地页链接
            },
textflagA:false,
textflagB:false,
textflagC:false,
textflagD:false,
textflagE:false,
textflagF:false,
textflagG:false,
textflagH:false,
textflagI:false,
textflagJ:false,
textflagK:false,
textflagL:false,
textflagM:false,
wendaA:false,
wendaB:false,
wendaC:false,
wendaD:false,
wendaE:false,
wendaF:false,
genter:'',
old:'',
info:'',
yiyuan:'',
lingqu:''
   }
  },
  created(){
    this.tg_id=this.$route.query.tg_id || 1

        this.ruleForm.land_link = window.location.href;
  },
  methods: {
    // 性别
selectGenter(type){
  type===0?this.genter='女':this.genter='男'
  this.wendaA=false
  this.textflagD=true

this.$nextTick(() => {
  window.scrollTo({
        top: window.pageYOffset + 200, // 当前滚动位置 + 200px
        behavior: 'smooth',
      });
         setTimeout(() => {
this.textflagE=true
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
      this.wendaB=true
    }, 800); // 1500毫秒 = 1.5秒
})
},
// 年龄
selectOld(type){
this.old=type
this.wendaB=false
this.textflagF=true

     window.scrollTo({
        top: window.pageYOffset + 200, // 当前滚动位置 + 200px
        behavior: 'smooth',
      });
      setTimeout(() => {
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
this.textflagG=true

      this.wendaC=true
    }, 800); // 1500毫秒 = 1.5秒
},

// 原因
selectinfo(type){
  this.info=type
  this.wendaC=false
  this.textflagH=true

        setTimeout(() => {
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
this.textflagI=true

this.wendaD=true
    }, 800); // 1500毫秒 = 1.5秒
},

// 意愿
selectyiyuan(type){
  type==='1'?this.yiyuan='不愿意':this.yiyuan='愿意'
  if (type!=='1') {
    this.text='好的,感谢您的回答,'
  }
  this.textflagJ=true
     window.scrollTo({
        top: window.pageYOffset + 500, // 当前滚动位置 + 200px
        behavior: 'smooth',
      });
  this.wendaD=false
          setTimeout(() => {
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
this.textflagK=true
  this.wendaE=true

    }, 800); // 1500毫秒 = 1.5秒
},
// 领取
selectLingqu(type){
  this.lingqu=type
  this.textflagL=true
this.$nextTick(() => {
  window.scrollTo({
        top: window.pageYOffset + 500, // 当前滚动位置 + 200px
        behavior: 'smooth',
      });
         setTimeout(() => {
this.textflagM=true
this.wendaE=false
this.wendaF=true
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
    }, 800); // 1500毫秒 = 1.5秒
})
},

// 点击事件
async onsubmit(){
    const {data} = await getWxCsad(this.ruleForm);
                window.location.href = data.data;
}
  },
  mounted() {
         window.scrollTo({
        top: 0, // 当前滚动位置 + 200px
        behavior: 'smooth',
      });
 setTimeout(() => {
      // 1秒后执行回调函数，将 textflagA 设置为 true
      this.textflagA = true;
    }, 600); // 1000毫秒 = 1秒

    setTimeout(() => {
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
      this.textflagB = true;
    }, 1000); // 1500毫秒 = 1.5秒
     setTimeout(() => {
      // 1.5秒后执行回调函数，将 textflagB 设置为 true
      this.textflagC = true;
      this.wendaA=true
    }, 1500); // 1500毫秒 = 1.5秒
  
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
 }
</script>

<style scoped lang='less'>
.bigDiv{
  width: 100%;
  min-height: 1367px;
  background-color: #eff0f5;
  .topImg{
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .gongsi{
    width: 280px;
    text-align: center;
    margin: 0 auto;
    color: #ccc;
  }
  .bodyCard{
    overflow: hidden;
  margin-bottom: 70px;


.text{
  display: flex;
  margin: 10px;
  img{
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .textinfo{
    position: relative;
    margin-left: 10px;
    background-color: #fff;
    font-size: 18px;
    line-height: 1.5em;
    border-radius: 6px;
     letter-spacing: 1px; /* 设置为2像素的间距 */
    padding:  8px;
  }
   .textinfo::before {
content: "";
    position: absolute;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
    left: -5px;
    top: 11px;
    z-index: 1;
    transform: rotate(-45deg);
  }
}
.userTExt{
   display: flex;
  margin: 10px;
  text-align: right;
  flex-direction: row-reverse;
    img{
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
    .textinfo{
    position: relative;
    margin-right: 10px;
    background-color: #a0e75a;
    font-size: 18px;

    border-radius: 6px;
     letter-spacing: 1px; /* 设置为2像素的间距 */
    padding:  10px;

  }
   .textinfo::before {
    content: "";
    position: absolute;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
    left: -5px;
    top: 11px;
    z-index: 1;
    transform: rotate(-45deg);
    border-left: 10px solid #a0e75a;
    left: unset;
    right: -5px;
    transform: rotate(134deg);
  }
}
  }
  .wenti{
    position: fixed;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    width: 375px;
    min-height: 60px;
    div{
width: 150px;
height: 40px;
line-height: 40px;
text-align: center;
background-color: #1855fb;
border-radius: 6px;
color: #fff;
font-size: 16px;
font-weight: 700;
margin: 10px;
    }
  }
  .btn_fixed{
    z-index: 999;
    width: 375px;
    height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
  background-image: url(https://oss.hshwhkj.com/images/btn-fxed.png);
  background-size: contain;
  background-repeat: no-repeat;
  .btn_fixed_btn{
    width: 80px;
    height: 30px;
    text-align: center;
    padding: 5px;
    background-color: #d0021b;
    border: none;
    position: absolute;
    right: 10px;
    top: 25px;
      animation-name: btnns;
        animation-duration: .5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
  }
  }
  @keyframes btnns {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
}
</style>